/*! ===================================
 *  Author: Nazarkin Roman, Egor Dankov
 *  -----------------------------------
 *  PuzzleThemes
 *  =================================== */

/***********************************************
 * Load theme fonts via WebFontLoader
 ***********************************************/
'use strict';
(function () {
    if (typeof SOPRANO_FONTS === 'undefined') {
        return;
    }

    WebFont.load({
        google: {families: SOPRANO_FONTS}
    });
})();